import React, {useCallback} from "react";

function App() {
    return (
        <>
          <div className="content">
            <h1>ExcursiaX: Social Media Platform For Travelers</h1>
            <p>
              ExcursiaX is a mobile application designed for travelers, enhancing the travel experience to be safer, more personalized, and socially connected. The app assists users in creating travel guides and provides instant communication with an AI-powered chat companion. Additionally, it offers essential features such as emergency notifications and exploring local cuisines, making travels more enjoyable and secure. ExcursiaX also allows you to seamlessly share your travel experiences on social media, making every moment of your journey unforgettable. It acts as your comprehensive travel companion, from planning to sharing your adventures.
            </p>
            <div className="logo">
              <img src="https://cdn.discordapp.com/attachments/914446216648097802/1155483129537101897/WhatsApp_Image_2023-09-23_at_18.53.43-removebg-preview.png"></img>
              <img src="https://cdn.discordapp.com/attachments/914446216648097802/1155633637602111508/1681038628chatgpt-icon-logo.png"></img>
            </div>
            <p style={{textAlign: "center"}} >
            Coming soon in 2024
            </p>
          </div>
        </>
    );
  }
  
  export default App;
  